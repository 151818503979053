.required-redbord .tox.tox-tinymce {
    border: 2px solid #E32739 !important;
    background-color: rgba(227, 39, 57, 0.1) !important;
}

.required-redbord .tox-edit-area__iframe {
    background-color: rgba(227, 39, 57, 0.1) !important;
}

.tox-tinymce--disabled .tox-edit-area__iframe {
    background-color: #E5E5E5 !important;
}

.tox:not(.tox-tinymce-inline) {
    .tox-tbtn {
        height: 32px;
    }

    .tox-tbtn,
    .tox-split-button {
        margin: 0;
        border-radius: 0;
    }

    button.tox-tbtn {
        width: 32px !important;
    }

    button[title="Insert/edit link"] {
        &:not(.tox-tbtn--disabled):hover {
            &>span {
                background: url(../assets/images/text-link-white.svg) no-repeat;
                background-position: center;
            }
        }

        &.tox-tbtn--disabled {
            &>span {
                background: url(../assets/images/text-link-gray.svg) no-repeat;
                background-position: center;
            }
        }

        &>span {
            width: 24px;
            height: 24px;
            background: url(../assets/images/text-link-black.svg) no-repeat;
            background-position: center;
        }

        svg {
            display: none;
        }
    }

    /* toolbar: enabled button:hover & button.enable:hover */
    .tox-tbtn.tox-tbtn--enabled:not(.tox-tbtn--disabled),
    .tox-split-button:not(.tox-tbtn--disabled) .tox-tbtn--enabled {
        background: #7500C0;
        border: #7500C0;

        svg {
            fill: #FFF;
        }

        &[title="Insert/edit link"] {
            &>span {
                background: url(../assets/images/text-link-white.svg) no-repeat;
                background-position: center;
            }
        }
    }

    .tox-tbtn:not(.tox-tbtn--disabled):not(.tox-tbtn--enabled):focus,
    .tox-tbtn:not(.tox-tbtn--disabled):not(.tox-tbtn--enabled):focus-visible,
    .tox-tbtn:not(.tox-tbtn--disabled):not(.tox-tbtn--enabled):hover {
        background: #A100FF;
        border: #A100FF;
    }

    .tox-split-button:active,
    .tox-split-button:focus,
    .tox-split-button:focus-visible,
    .tox-split-button:hover {
        box-shadow: 0 0 0 2px #A100FF inset;
    }

    .tox-split-button:not(.tox-tbtn--disabled) .tox-tbtn.tox-split-button__chevron:active,
    .tox-split-button:not(.tox-tbtn--disabled) .tox-tbtn.tox-split-button__chevron:focus,
    .tox-split-button:not(.tox-tbtn--disabled) .tox-tbtn.tox-split-button__chevron:focus-visible,
    .tox-split-button:not(.tox-tbtn--disabled) .tox-tbtn.tox-split-button__chevron:hover {
        background: #A100FF;
    }

    .tox-split-button:not(.tox-tbtn--disabled) .tox-tbtn.tox-split-button__chevron.tox-tbtn--enabled:active,
    .tox-split-button:not(.tox-tbtn--disabled) .tox-tbtn.tox-split-button__chevron.tox-tbtn--enabled:focus,
    .tox-split-button:not(.tox-tbtn--disabled) .tox-tbtn.tox-split-button__chevron.tox-tbtn--enabled:focus-visible,
    .tox-split-button:not(.tox-tbtn--disabled) .tox-tbtn.tox-split-button__chevron.tox-tbtn--enabled:hover {
        background: #A100FF;
    }

    .tox-tbtn.tox-tbtn--enabled:not(.tox-tbtn--disabled):active,
    .tox-tbtn.tox-tbtn--enabled:not(.tox-tbtn--disabled):focus,
    .tox-tbtn.tox-tbtn--enabled:not(.tox-tbtn--disabled):focus-visible,
    .tox-tbtn.tox-tbtn--enabled:not(.tox-tbtn--disabled):hover {
        background: #A100FF;
        border: #A100FF;
    }

    .tox-split-button:not(.tox-tbtn--disabled) .tox-tbtn--enabled:active,
    .tox-split-button:not(.tox-tbtn--disabled) .tox-tbtn--enabled:focus,
    .tox-split-button:not(.tox-tbtn--disabled) .tox-tbtn--enabled:focus-visible,
    .tox-split-button:not(.tox-tbtn--disabled) .tox-tbtn--enabled:hover {
        background: #A100FF;
    }

    /* toolbar button:hover & button.enable:hover end */
}

.tox:not(.tox-tinymce-inline).tox-tinymce {
    border-radius: 4px;
    border: 1px solid #4F4B53;

    .tox-editor-header {
        padding: 0;
        box-shadow: none;
        border-bottom: 1px solid #B3B2B5;

        .tox-toolbar__primary {
            background-color: #F2F2F2 !important;

            .tox-toolbar__group {
                padding: 0;
            }

            .tox-toolbar__group .tox-split-button:last-child {
                margin-right: 8px;
            }

            .tox-toolbar__group:not(:last-of-type) {
                border-right: 1px solid #B3B2B5;
            }
        }
    }
}


.tox:not(.tox-tinymce-inline) {

    .tox-collection--toolbar .tox-collection__item--enabled.tox-collection__item--active,
    .tox-collection--toolbar .tox-collection__item--active {
        background-color: #A100FF;
    }
}


.rich--text--formatter {
    background-color: #E5E5E5;
    line-height: 20px;
    color: #000000;
    border: solid 1px #837F89 !important;
    // min-height: 100px;
    height: 100px;
    font-family: "graphik" !important;
    font-size: 14px;
    padding: 7px 10px;
    border-radius: 4px;
    word-break: break-word;
    overflow-y: auto;

    .underline {
        text-decoration: underline;
    }

    ol {
        list-style: decimal;
    }

    // ul, li {
    //     list-style: inherit;
    // }

    ol, ul {
        padding-inline-start: 20px;
    }

}
.tox-form .tox-form__group .tox-form__controls-h-stack .tox-control-wrap {
    input:hover {
        border: 2px solid #A100FF !important;
        height: 30px
    }
    input:focus {
        border: 2px solid #A100FF !important;
        height: 30px
    }
}

.tox-form .tox-form__group {
    input:hover {
        border: 2px solid #A100FF !important;
        height: 30px
    }
    input:focus {
        border: 2px solid #A100FF !important;
        height: 30px
    }
    .tox-listboxfield button:hover {
        border: 2px solid #A100FF !important;
        height: 30px
    }
    .tox-listboxfield button:focus {
        border: 2px solid #A100FF !important;
        height: 30px
    }
    .tox-form__controls-h-stack .tox-control-wrap .tox-textfield {
        height: 30px;
    }
    .tox-textfield {
        height: 30px;
    }
    .tox-listboxfield .tox-listbox--select {
        height: 30px;
    }
}

.tox-dialog .tox-dialog__footer .tox-dialog__footer-end .tox-button--secondary:hover {
    border-radius: 999px;
    border: 2px solid #A100FF !important;
    padding: 0px 16px
}

