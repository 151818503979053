$--primary:#a125fc;
$--primary-bg:#f5f6f8;
$--invalid: #ff3246;
$--drop-border: #4f4b53;
// $--drop-border: #b3b2b5;
$--drop-border-active: #707070;
$--drop-placeholder: $--drop-border-active;
$--drop-text: #000000;
$--panel-border: #e5e5e5;
$--selector-text: #565656;
$--selector-default:#4f4b53;
$--panel-shadow:#000029;
$--bg: #ffffff;
$--scrollbar-track: #f2f2f2;
$--scrollbar-thumb: #837f89;
$--regular-border: $--selector-default;
$--regular-border-focus: #a100ff;
$--regular-border-hover: #a125fc;
$--regular-placeholder: #7a7a7a;
$--regular-disabled: #d9d9d9;
$--regular-readonly-bg:$--panel-border;
$--regular-readonly-border:$--selector-default;
$--regular-readonly-text: #837f89;
$--switch--border:$--scrollbar-thumb;
$--outline:#0041f0;
$--switch--slider:#cccbce;
$--btn-disabled-bg: $--scrollbar-track;
$--btn-disabled-icon: #afafaf;
$--calendar-td:#36313b;
$font:normal normal normal 16px/20px Graphik;

//Dropdown
.ui-dropdown,
.ui-multiselect {
    padding: 0;
    border: 1px solid $--drop-border;
    // border: 2px solid $--drop-border;
    border-radius: 2px;
    width: 100%;
    height: 48px;

    &.ui-widget {
        font: $font;
    }

    .pi {
        &.pi-chevron-down {
            left: auto;
            right: 18px;
        }
    }
}

.ng-touched.ng-invalid {

    .ui-dropdown,
    .ui-multiselect {
        border-color: $--invalid;
    }
}

.ng-untouched.ng-invalid,
.ng-untouched.ng-valid,
.ng-touched.ng-valid,
.ng-dirty.ng-touched.ng-valid {

    .ui-dropdown,
    .ui-multiselect {
        border-color: $--drop-border;
    }
}

.ui-dropdown:not(.ui-state-disabled):hover,
.ui-multiselect:not(.ui-state-disabled):hover {
    border-color: $--regular-border-hover;
}

//singleSelect
.drop-single {

    &.ui-state-focus,
    .ui-dropdown-open {
        border-color: $--regular-border-focus;
        // border-color: $--drop-border-active;
        box-shadow: none;
    }

    &.ui-dropdown-open .pi-chevron-down:before {
        content: "\e903";
    }

    .ui-inputtext {
        font: $font;
        color: $--drop-text;
    }

    .ui-placeholder {
        color: $--drop-placeholder;
    }

    .ui-dropdown-label {
        line-height: 30px;
    }
}

.drop-panel {
    padding: 16px 0 8px;
    border: 1px solid $--panel-border;
    border-radius: 0 0 2px 2px;
    height: auto;
    box-shadow: 0px 3px 6px $--panel-shadow;

    .ui-dropdown-item {
        padding: 14px 0 14px 16px;
        height: 48px;
        color: $--drop-text;

        &.ui-state-highlight {
            background: $--primary;
        }
    }
}

//multiSelect
.ui-inputwrapper-filled {
    .drop-multi {
        .ui-multiselect-label {
            color: $--drop-text;
        }
    }
}

.drop-multi {
    &.ui-state-focus,
    .ui-multiselect-open {
        border-color: $--regular-border-focus;
        // border-color: $--drop-border-active;
        box-shadow: none;
    }

    &.ui-multiselect-open .pi-chevron-down:before {
        content: "\e903";
    }

    .ui-multiselect-label {
        line-height: 30px;
        color: $--drop-placeholder;
    }
}

.drop-mul-panel {
    padding: 16px 0 8px;
    border: 1px solid $--panel-border;
    border-radius: 0 0 2px 2px;
    height: 264px;
    box-shadow: 0px 3px 6px $--panel-shadow;

    .ui-multiselect-item {
        padding: 14px 0 14px 16px;
        height: 48px;
        color: $--drop-text;

        &.ui-state-highlight {
            background: $--bg;
            color: $--drop-text;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .ui-multiselect-header {
        padding-left: 14px;

        .pi-search {
            color: $--drop-placeholder;
        }

        .ui-inputtext:focus {
            box-shadow: none;
            border-color: $--primary;

            &+.pi-search {
                color: $--primary;
            }
        }

        .ui-multiselect-close:focus {
            box-shadow: none;
        }
    }

    .ui-multiselect-item,
    .ui-multiselect-header {
        .ui-chkbox-box {
            &.ui-state-active {
                background: $--primary;
                border-color: $--primary;
            }
        }
    }
}

//Selectors
.radioLable,
.chxbox-label {
    margin-left: 12px;
    vertical-align: middle;
    font: normal normal normal 16px/24px Graphik;
    color: $--selector-text;
}

//radio
.radio-sel {
    margin: 10px 0;

    .ui-radiobutton-box {
        border: 2px solid $--selector-default;

        &.ui-state-active,
        &.ui-state-active:hover {
            border-color: $--primary;
            background: $--primary;
            box-shadow: none;
        }

        .ui-radiobutton-icon {
            left: 25%;
            top: 25%;
            margin: 0;
            width: 8px;
            height: 8px;
        }
    }
}

//checkbox
.chxbox-sel {
    margin: 10px 0;

    .ui-chkbox-box {
        border: 2px solid $--selector-default;

        &.ui-state-active,
        &.ui-state-active:hover {
            border-color: $--primary;
            background: $--primary;
            box-shadow: none;
        }

        &.ui-state-focus {
            border-color: $--selector-default;
            box-shadow: none;
        }

        .ui-chkbox-icon {
            line-height: 16px;
        }
    }
}

//Regular
.regular {
    position: relative;
    display: inline-block;
    width: 100%;
    overflow: hidden;

    .pi.pi-ban {
        display: none;
        position: absolute;
        top: 4px;
        right: 4px;
        width: 28px;
        height: 40px;
        line-height: 40px;
        background: $--bg;
        font-size: 18px;
        color: $--invalid;
    }

    &-input {
        width: 100%;
        height: 48px;
        border: 1px solid $--regular-border;
        border-radius: 2px;
        box-shadow: none;
        background-origin: padding-box;
        font: $font;

        &:enabled:hover:not([readonly]) {
            border-color: $--primary;
        }

        &.ui-inputtext {
            padding-top: 8px;
            padding-bottom: 8px;

            // &::-webkit-input-placeholder{
            //   color: $--regular-placeholder;
            // }
            &:enabled:focus:not(.ui-state-error) {
                box-shadow: none;

                &.ng-valid,
                &.ng-untouched {
                    border: 4px solid $--regular-border-focus;
                }
            }

            &.ng-invalid.ng-touched {
                border: 4px solid $--invalid;

                &+.pi.pi-ban {
                    display: inline-block;
                }
            }

            &:not([disabled]):not([readonly]).ng-valid {
                &:hover {
                    border-color: $--regular-border-hover;
                }

                &:focus {
                    box-shadow: none;
                    border: 4px solid $--regular-border-focus;
                }
            }

            &.ui-state-filled {
                color: $--drop-text;
            }
        }

        &:read-only {
            cursor: default;
            border-color: $--regular-readonly-border;
            background-color: $--regular-readonly-bg;
            color: $--regular-readonly-text;

            &:focus,
            &:hover {
                border-color: $--regular-readonly-text;
            }
        }

        &:disabled {
            cursor: default;

            &.ui-inputtext {
                border-color: $--regular-disabled;
                color: $--regular-disabled;

                &:hover {
                    border-color: $--regular-disabled;
                }
            }
        }
    }
}

//InputNumber
.input-number {
    .ui-button {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border-color: $--regular-border-focus;
        background: $--regular-border-focus;

        .pi {
            font-size: 16px;
        }

        &-plus {
            margin-left: 16px;
        }

        &-minus {
            margin-right: 16px;
        }

        &:enabled:focus {
            box-shadow: 0 0 0 5px $--bg,
                0 0 0 6px $--outline;
        }

        &:disabled {
            background: $--btn-disabled-bg;
            border-color: $--btn-disabled-bg;

            .pi {
                color: $--btn-disabled-icon;
            }
        }
    }

    .ui-inputnumber-input {
        height: 48px;
        width: 120px;
        border-color: $--selector-default;
        text-align: center;
        font: $font;
        color: $--drop-text;

        &.ui-inputtext {
            &:enabled:focus:not(.ui-state-error) {
                box-shadow: none;
                border-color: $--selector-default;
            }
        }
    }
}

//Toggle
.input-switch {
    position: relative;

    .pi.pi-check {
        display: none;
        position: absolute;
        top: 4px;
        left: 10px;
        font-size: 16px;
        color: $--bg;

        &.show-icon {
            display: block;
        }
    }

    .switch {
        width: 50px;
        height: 24px;
        border: 2px solid $--switch--border;
        border-radius: 20px;

        .ui-inputswitch-slider {
            background: $--bg;

            &::before {
                top: 2px;
                left: 1px;
                height: 16px;
                width: 16px;
                background-color: $--switch--border;
            }

            &:hover {
                background: $--bg;
            }
        }

        &:enabled:hover {
            border-color: $--primary;
            background: $--bg;
        }

        &.ui-inputswitch:not(.ui-state-disabled):hover .ui-inputswitch-slider {
            background-color: $--bg;
        }

        &.ui-inputswitch-focus {
            .ui-inputswitch-slider {
                box-shadow: none;

                &:before {
                    background-color: $--switch--slider;
                }
            }

            box-shadow: 0 0 0 2px $--bg,
            0 0 0 3px $--outline;
        }

        &.ui-inputswitch-checked,
        &.ui-inputswitch-checked:not(.ui-state-disabled):hover {
            border-color: $--primary;
            background: $--primary;

            .ui-inputswitch-slider {
                background: $--primary;

                &::before {
                    top: 2px;
                    right: 1px;
                    background: $--bg;
                }
            }
        }

        &.ui-inputswitch-checked {
            .ui-inputswitch-slider:before {
                transform: translateX(28px);
            }
        }
    }
}

//Pageinator
.pageinator {
    border: none;
    text-align: left;

    .ui-paginator-first,
    .ui-paginator-last {
        display: none;
    }

    .ui-paginator-element {

        // &.ui-state-disabled{
        //   opacity: 1;
        // }
        &.ui-paginator-next,
        &.ui-paginator-prev {
            height: 48px;
            width: 48px;
            border-radius: 50%;
            background: $--primary;

            &:not(.ui-state-disabled):not(.ui-state-active):hover {
                background: $--primary;
            }

            &:focus {
                box-shadow: 0 0 0 5px $--bg,
                    0 0 0 6px $--outline;
            }
        }

        .pi {
            font-size: 16px;
            color: $--bg;

            &.pi-caret-left {
                &::before {
                    content: "\e900";
                }
            }

            &.pi-caret-right {
                &::before {
                    content: "\e901";
                }
            }
        }
    }

    .ui-paginator-pages {
        background-color: $--primary-bg;
        padding: 0 5px;

        .ui-paginator-page {
            &:not(.ui-state-active):hover {
                background: $--bg;
            }
        }

        .ui-paginator-element {
            min-width: 45px;
            border-radius: 0;
            background-color: $--primary-bg;
            font: normal normal normal 22px/48px Graphik;
            color: $--drop-text;

            &:focus {
                box-shadow: none;
            }

            &.ui-state-active {
                line-height: 45px;
                font-weight: bold;

            }
        }
    }
}

//Calendar
.calendar {
    &:not(.ui-state-disabled) {
        .ui-datepicker-calendar {
            td {
                a {
                    border: 2px solid $--bg;
                    border-radius: 50%;
                }

                a:not(.ui-state-active):not(.ui-state-highlight):hover {
                    border-color: $--drop-border;
                    background-color: $--scrollbar-track;
                }

                a:not(.ui-state-active):not(.ui-state-highlight):focus {
                    border-color: $--outline;
                    background-color: $--scrollbar-track;
                }

                a:focus {
                    box-shadow: none;

                }

                // &.ui-datepicker-today a{
                //   background-color: $--calendar-td;
                //   color: $--bg;
                // }
                &.ui-datepicker-current-day {
                    a.ui-state-active {
                        background-color: $--calendar-td;
                        border-color: $--calendar-td;
                        color: $--bg;
                    }
                }
            }
        }
    }

    &-panel {
        box-shadow: 0px 4px 8px $--panel-shadow;
        border: 1px solid $--selector-default;
        border-radius: 8px;
        font: normal normal 600 16px/20px Graphik;

        .ui-datepicker-header {
            color: $--drop-text;

            .ui-datepicker-prev,
            .ui-datepicker-next {
                font-size: 20px;
                color: $--primary;
            }
        }
    }

    &-input {
        border: 1px solid $--regular-border;
        border-radius: 2px;
        width: 280px;
        height: 48px;
        box-shadow: none;

        &.ui-inputtext {
            font: $font;
            color: $--drop-text;

            &:enabled:focus:not(.ui-state-error) {
                box-shadow: none;
                border-color: $--primary;
            }
        }
    }
}

//Pill Selection
.pill {
    position: relative;
    padding: 8px 16px;
    border: 1px solid $--selector-default;
    border-radius: 999px;
    width: 132px;
    height: 32px;
    background: $--bg;
    font: $font;
    line-height: 18px;
    color: $--selector-default;
    text-align: left;
    cursor: default;

    &:hover {
        border-color: $--scrollbar-thumb;
        background: $--bg;
        color: $--scrollbar-thumb;

        .ui-button {
            .pi.pi-times {
                color: $--scrollbar-thumb;
            }
        }
    }

    &:focus {
        box-shadow: 0 0 0 3px $--bg,
            0 0 0 4px $--outline;
    }

    .ui-button {
        position: absolute;
        top: 2px;
        right: 10px;
        border: none;
        width: 12px;
        height: 26px;
        background: $--bg;
        color: $--scrollbar-thumb;
        cursor: pointer;

        &:enabled:focus {
            box-shadow: none;
        }

        .pi.pi-times {
            font-size: 16px;
            color: $--selector-default;
        }
    }
}

.show-label {
    display: none;
}

//AutoComplete
.autocomplete {
    position: relative;
    width: 280px;

    .errorBorder {
        .ui-autocomplete-input {
            border: 4px solid $--invalid;
        }
    }

    .pi.pi-ban {
        display: none;
        position: absolute;
        top: 4px;
        right: 4px;
        width: 28px;
        height: 40px;
        line-height: 40px;
        background: $--bg;
        font-size: 16px;
        color: $--invalid;
    }

    .ui-autocomplete {
        width: 100%;
    }

    .ui-autocomplete-input {
        width: 100%;
        height: 48px;
        border: 1px solid $--regular-border;
        border-radius: 2px;
        font: $font;

        &:enabled:hover:not([readonly]) {
            border-color: $--primary;
        }

        &.ui-inputtext {
            &:enabled:focus:not(.ui-state-error) {
                box-shadow: none;
                border: 4px solid $--regular-border-focus;
            }

            &.ui-state-filled {
                color: $--drop-text;
            }
        }
    }

    .ng-invalid {

        &.ng-dirty,
        &.ng-touched {
            .ui-autocomplete {
                .autocomplete-input {
                    border: 4px solid $--invalid;
                }
            }

            &+.pi.pi-ban {
                display: inline-block;
            }
        }
    }

    &-panel {
        border: 1px solid $--panel-border;
        border-radius: 0px 0px 2px 2px;
        box-shadow: 0px 3px 6px $--panel-shadow;

        .ui-autocomplete-items {
            margin: 5px 0 15px;

            .ui-autocomplete-list-item {
                height: 40px;
                font: $font;
                line-height: 26px;
                color: $--drop-text;

                &.ui-state-highlight {
                    background-color: $--scrollbar-track;
                    color: $--drop-text;

                }
            }
        }
    }

    //multi
    .ui-autocomplete-multiple-container {
        border: 1px solid $--regular-border;
        border-radius: 2px;
        width: 100%;
        min-height: 48px;

        &:not(.ui-state-disabled):hover {
            border-color: $--primary;
        }

        &:not(.ui-state-disabled).ui-state-focus {
            box-shadow: none;
            border: 4px solid $--regular-border-focus;
            color: $--drop-text;

            .autocomplete-input {
                height: 28px;
            }
        }

        .ui-autocomplete-token {
            margin: 5px;
            border-radius: 999px;
            background: $--scrollbar-track;
            font: $font;
            color: $--drop-text;

            .ui-autocomplete-token-icon {
                font-size: 16px;
            }
        }
    }

    .ui-autocomplete-input-token {
        .autocomplete-input {
            width: 100%;
            height: 32px;
            font: $font;
        }
    }
}

// Message
.ui-message {
    &.ui-message-error {
        padding: 0;
        box-shadow: none;
        min-height: 22px;
        background-color: transparent;
        font: $font;
        color: $--invalid;

        .ui-message-icon {
            display: none;
        }
    }
}
