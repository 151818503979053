@use "sass:math";
// Color variable
$color-azure-blue: #2473c3;
$color-white: #fff;
$color-black: #000;
$color-grey-blue: #aac1ce;
$color-purple: #b894db;
$color-aqua: #82d2eb;
$color-blue-pearl: #98b9e9;
$color-teal: #77ccce;
$color-blush: #ff8d8d;
$color-honey: #ffd272;
$color-tangerine: #ffad75;
$color-true-blue: #1e5d95;
$color-powder-blue: #eaf3fa;
$color-super-light-grey: #f5f6f8;
$color-regular: #4d5d72;
$color-grey: #65758b;
// rem size
$base-size: 14px;
$base-size-small: 12x;
// container width height
$full-width: 100%;
$full-height: 100%;
$Adaptive-height: 100vh;
$menu-width: 3rem;
$fold-menu-width: 0.7rem;

//scsscomponent
$ul-style: circle;

@mixin mixin-min-scroll {
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 100px;
        background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
        width: 8px;
        height: 9px;
        background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background: rgba(0, 0, 0, 0.26);
    }
}

@mixin mixin-min-scroll-menu {
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 100px;
        background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
        width: 6px;
        height: 9px;
        background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background: rgba(0, 0, 0, 0.26);
    }
}

@mixin mixin-min-scroll-text {
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 0px rgb(255 255 255 / 0%);
        border-radius: 10px;
        background-color: #FFF;
    }
    &::-webkit-scrollbar {
        width: 10px;
        height: 0px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background: rgba(0, 0, 0, 0.26);
    }
}

@mixin glr-inner-scroll {
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 0px rgb(255 255 255 / 0%);
        border-radius: 10px;
        background-color: #FFF;
    }
    &::-webkit-scrollbar {
        width: 6px;
        height: 0px;
        background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background: rgba(0, 0, 0, 0.26);
    }
}

// layput color
$primary-color: #8c44a3;
$paimary-color-hover: rgba(140, 68, 163, 0.6);
$menu-bg-color: rgba(0, 0, 0, 0.87);
$header-footer-bg-color: #fff;
$hamburger-icon: aliceblue;

//common color
$corePurple-color-one: #a100ff;
$corePurple-color-two: #7500c0;
$corePurple-color-three: #460073;
$action-color-one: #a100ff;
$action-color-two: #0041f0;
$accentPurple-color-one: #b455aa;
$accentPurple-color-two: #a055f5;
$accentPurple-color-three: #be82ff;
$accentPurple-color-four: #dcafff;
$accentPurple-color-five: #e6dcff;
$greyUI-color-white: #ffffff;
$greyUI-color-black: #000000;
$greyUI-color-alabaster: #fafafa;
$greyUI-color-whiteSmoke: #f2f2f2;
$greyUI-color-gainsboro: #e5e5e5;
$greyUI-color-ghost: #cccbce;
$greyUI-color-frenchGrey: #b3b2b5;
$greyUI-color-topaz: #837f89;
$greyUI-color-gravel: #4f4b53;
$greyUI-color-shipGrey: #3b3944;
$greyUI-color-bastille: #1d1823;
$brandNeutralGreys-color-one: #e6e6dc;
$brandNeutralGreys-color-two: #96968c;

$secondary-color-one: #0041f0;
$secondary-color-two: #00ffff;
$secondary-color-three: #64ff50;
$secondary-color-four: #05f0a5;
$secondary-color-five: #ff3246;
$secondary-color-six: #ff50a0;
$secondary-color-seven: #ff7800;
$secondary-color-eight: #ffeb32;

@function px2rem($px, $base : 14px) {
    @return math.div($px, $base) * 1rem;
}

$font-14: 14px;
$font-16: 16px;
$font-24: 24px;


@mixin glr-common-button {

    button {
        background-color: none;
        border: 1px solid rgba(0,0,0,0);
        box-shadow: none;
        color: $corePurple-color-one;
        height: px2rem(36px);
        display: flex;
        align-items: center;
        font-size: px2rem(14px);
        display: flex;
        align-items: center;
        font-family: "graphik";
        box-sizing: border-box;
        font-weight: 600;
        &:first-of-type:hover {
            border: 1px solid #7500c0;

        }
    }
    button + button {
        font-family: "graphik";
        box-sizing: border-box;
        font-weight: 600;
        margin-left: 0.8rem;
        background-color: $corePurple-color-one;
        color: #fff;
        position: relative;
        &:hover {
            background: $corePurple-color-two;
        }

        &:focus {
            &::before {
                content: "";
                position: absolute;
                width: calc(100% + 10px);
                height: calc(100% + 10px);
                border-radius: 1.5rem;
                border: 1px solid $action-color-two;
                display: block;
                left: -5px;
                top: -5px;
            }
        }
    }

}

@mixin wizard-shadow-hidden($LeftOffset: -8px, $ExtraWidth: 16px) {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    &::before {
        content: "";
        position: absolute;
        left: $LeftOffset;
        bottom: -12px;
        height: 12px;
        width: calc(100% + $ExtraWidth);
        background-color: #FFFFFF;
    }
}

@mixin multiple-checked-font-bord {
     .ant-checkbox-wrapper-checked {
     > span {
     // font-family: 'graphik-s' !important;
         }
     }
}

// viewall-mark-update-readytoassign button UI
@mixin glr-process-button($DivWidth, $LabelWidth, $ToWidth, $FocusLabelWidth, $FocusLabelLeft, $Content, $FocusContent, $cartoon) {

    height: 36px;
    width: $DivWidth;
    display: block;
    cursor: pointer;
    position: relative;

    .action-btn-icon {
        width: 36px;
        height: 36px;
        color: #FFF;
        display: inline-block;
        border-radius: 36px;
        background: #0041F0;
        padding-left: 14px;
        line-height: 34px;
        font-size: 24px;
        z-index: 1;
    }

    .action-btn-label {
        z-index: 2;
        left: 46px;
        width: $LabelWidth;
        position: absolute;
        font: 18px/34px graphik-b;

        &::before {
            content: $Content;
        }
    }

     .mark-btn-text-disabled {
        color: #b3b2b5;
    }

    &:hover,
    &:focus {
        width: $ToWidth;

        .action-btn-icon {
            border-radius: 36px;
            animation-delay: .2s;
            animation: #{$cartoon} .6s ease;
            animation-fill-mode: forwards;
        }

        .action-btn-label {
            width: $FocusLabelWidth;
            color: #FFF;
            left: $FocusLabelLeft;

            &::before {
                content: $FocusContent;
            }
        }

        .requesting-label {
            &::before {
                content:  $FocusContent;
            }
        }
        @keyframes  #{$cartoon} {
            from {
                width: 36px;
            }

            to {
                width: $ToWidth;
            }
        }
    }
}

.text-dis {
    color: #737373 !important;
}

.div-disable {
    pointer-events: none;
    .action-btn-icon {
        background-color: #b3b4b5 !important;
    }

    .action-btn-label {
        color: #b3b4b5 !important;
    }
}
