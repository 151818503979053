/* You can add global styles to this file, and also import other style files */
@import "../node_modules/font-awesome/css/font-awesome.min.css";
@import "../node_modules/ng-zorro-antd/style/index.min.css"; /* 引入基本样式 */
@import "../node_modules/ng-zorro-antd/tree-select/style/index.min.css"; /* 引入组件样式 */

// select tree style start
@import 'src/assets/base.scss';
@import 'src/assets/form.scss';

*,
 *::before,
 *::after {
   box-sizing: border-box;
 }

 @-ms-viewport {
   width: device-width;
 }

 article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
   display: block;
 }

html, body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form,
fieldset, input, textarea, p, blockquote, th, td, aside, header, label {
    /* Script below is causing an issue on displaying the PrimeNg Multiselect.
    margin: 0;
    padding: 0;
    */
}


@font-face {
    font-family: 'graphik';
    font-style: normal;
    font-weight: 400;
    src: url('assets/fonts/Graphik-Regular.woff2') format('woff2'),
    url('assets/fonts/Graphik-Regular.woff') format('woff');
}

@font-face {
    font-family: 'graphik-m';
    font-style: normal;
    font-weight: 500;
    src: url('assets/fonts/Graphik-Medium.woff2') format('woff2'),
    url('assets/fonts/Graphik-Medium.woff') format('woff');
}

@font-face {
    font-family: 'graphik-s';
    font-style: normal;
    font-weight: 600;
    src: url('assets/fonts/Graphik-Semibold.woff2') format('woff2'),
    url('assets/fonts/Graphik-Semibold.woff') format('woff');
}

@font-face {
    font-family: 'graphik-b';
    font-style: normal;
    font-weight: 700;
    src: url('assets/fonts/Graphik-Bold.woff2') format('woff2'),
    url('assets/fonts/Graphik-Bold.woff') format('woff');
}

html, body {
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-family: 'Graphik Regular','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

    @media screen and (max-width: 1920px) {
        font-size: 18px;
    }

    @media screen and (min-width: 1441px) and (max-width: 1600px) {
        font-size: 16px;
    }

    @media screen and (min-width: 1281px) and (max-width: 1400px) {
        font-size: 14px;
    }

    @media screen and (max-width: 1280px) {
        font-size: 12px;
    }
}

.ng-untouched.ng-invalid .ui-dropdown, .ng-untouched.ng-invalid .ui-multiselect, .ng-untouched.ng-valid .ui-dropdown, .ng-untouched.ng-valid .ui-multiselect, .ng-touched.ng-valid .ui-dropdown, .ng-touched.ng-valid .ui-multiselect, .ng-dirty.ng-touched.ng-valid .ui-dropdown, .ng-dirty.ng-touched.ng-valid .ui-multiselect {
    border: 1px solid #4F4B53;
    border-radius: px2rem(5px);
    width: px2rem(588px);
    height: px2rem(36px);
}
.ant-picker-today-btn,.ant-picker-today-btn:hover,.ant-picker-header-view button:hover,.ant-picker-header > button:hover {
    color: #A100FF
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: #A100FF;
    border-radius: px2rem(13px);
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #A100FF;
    border-radius: px2rem(13px);
}
.ant-picker-cell .ant-picker-cell-inner {
    border-radius: px2rem(35px);
}

.ant-picker-focused {
    box-shadow: none
}

.ant-tooltip-content {
    border: 2px solid #B3B3B5;
    box-shadow: 0px 8px 16px rgb(0,0,0,0.16);
}

[tabindex="-1"]:focus {
    outline: 0 !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    margin-top: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

fieldset, img {
    border: 0;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

address, caption, cite, code, dfn, th, var {
    font-style: normal;
    font-weight: normal;
}

ol, ul, li {
    margin-top: 0;
    // list-style: none;
}

caption, th {
    text-align: left;
}


// li {
//     list-style-type: none;
// }

.tc {
    text-align: center;
}

.clear {
    clear: both;
}

.po-e-n {
    pointer-events: none;
}

div:focus-visible {
    outline: none;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
  border: none;
  -webkit-appearance: none;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

.mt8 {
    margin-top: 8px;
}

.mt12 {
    margin-top: 12px;
}

.ml16 {
    margin-left: 16px !important;
}

.mr16 {
    margin-right: 16px !important;
}

.mb5 {
    margin-bottom: 5px !important;
}

.fr {
    float: right;
}

//Font
.graphik-blod-136
{
    height: 187px;
    text-align: left;
    font: normal normal bold 136px/136px Graphik;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.graphik-blod-64-76
{
    height: 88px;
    text-align: left;
    font: normal normal bold 64px/76px Graphik;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.graphik-blod-64-64{
    height: 88px;
    text-align: left;
    font: normal normal bold 64px/64px Graphik;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}


.graphik-blod-96{
    height: 131px;
    text-align: left;
    font: normal normal bold 96px/96px Graphik;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.graphik-blod-40-48{
    height: 54px;
    text-align: left;
    font: normal normal bold 40px/48px Graphik;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.graphik-blod-40-40{
    height: 54px;
    text-align: left;
    font: normal normal bold 40px/40px Graphik;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.graphik-blod-24{
    height: 33px;
    text-align: left;
    font: normal normal bold 24px/28px Graphik;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.graphik-normal-22{
    height: 26px;
    text-align: left;
    font: normal normal normal 22px/33px Graphik;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.graphik-blod-32{
    height: 43px;
    text-align: left;
    font: normal normal bold 32px/38px Graphik;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.graphik-medium-16{
    height: 22px;
    text-align: left;
    font: normal normal 16px/24px Graphik;
    font-family: Graphik-Medium;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.graphik-normal-16{
    height: 19px;
    text-align: left;
    font: normal normal normal 16px/24px Graphik;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.graphik-blod-16{
    height: 22px;
    text-align: left;
    font: normal normal bold 16px/24px Graphik;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    opacity: 1;
}

.arial-bold-72{
    top: 80px;
    left: 80px;
    width: 584px;
    height: 82px;
    text-align: left;
    font: normal normal bold 72px/24px Arial;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.arial-normal-16{
    width: 170px;
    height: 113px;
    text-align: left;
    font: normal normal normal 16px/24px Arial;
    letter-spacing: 0px;
    color: #696969;
    opacity: 1;
}

.arial-normal-32{
    height: 36px;
    text-align: center;
    font: normal normal normal 32px/24px Arial;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.arial-bold-48{
    height: 53px;
    text-align: left;
    font: normal normal bold 48px/24px Arial;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

//textLink
.textLink{
    height: 19px;
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 16px/19px Graphik;
    letter-spacing: 0px;
    color: #0041F0;
    opacity: 1;
}
.textLink:hover{
    height: 19px;
    opacity: 1;
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 16px/19px Graphik;
    letter-spacing: 0px;
    color: #0041F0;
    // background: #0041F014;
}
.textLink:focus{
    height: 19px;
    border:1px solid #0041F0;
    text-align: left;
    text-decoration: none;
    font: normal normal normal 16px/19px Graphik;
    letter-spacing: 0px;
    color: #0041F0;
    opacity: 1;
    // background: #0041F014;
}
.textLink:active {
    height: 19px;
    border:none;
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 16px/19px Graphik;
    letter-spacing: 0px;
    color: #460B73;
    opacity: 1;
    background: none;
}

.textLink-inactive{
    height: 19px;
    text-align: left;
    text-decoration: underline line-through;
    font: normal normal normal 16px/19px Graphik;
    letter-spacing: 0px;
    color: #B3B2B5;
    opacity: 1;
}

//textLink-second
.textLink-second{
    height: 19px;
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 16px/19px Graphik;
    letter-spacing: 0px;
    color: #4F4B53;
    opacity: 1;
}
.textLink-second:hover{
    height: 19px;
    opacity: 1;
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 16px/19px Graphik;
    letter-spacing: 0px;
    color:#4F4B53;
    // background: #E5E5E5;
}
.textLink-second:focus{
    height: 19px;
    border:1px solid #0041F0;
    text-align: left;
    text-decoration: none;
    font: normal normal normal 16px/19px Graphik;
    letter-spacing: 0px;
    color: #4F4B53;
    opacity: 1;
    // background: #E5E5E5;
}
.textLink-second:active {
    height: 19px;
    border:none;
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 16px/19px Graphik;
    letter-spacing: 0px;
    color: #460B73;
    opacity: 1;
    background: none;
}

//textLink-dar
.textLink-dark{
    height: 19px;
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 16px/19px Graphik;
    letter-spacing: 0px;
    color: #00FFFF;
    opacity: 1;
}
.textLink-dark:hover{
    height: 19px;
    opacity: 1;
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 16px/19px Graphik;
    letter-spacing: 0px;
    color: #00FFFF;
    // background: #00FFFF3C;
}
.textLink-dark:focus{
    height: 19px;
    border:1px solid #00FFFF;
    text-align: left;
    text-decoration: none;
    font: normal normal normal 16px/19px Graphik;
    letter-spacing: 0px;
    color: #00FFFF;
    opacity: 1;
    // background: #00FFFF3C;
}
.textLink-dark:active {
    height: 19px;
    border:none;
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 16px/19px Graphik;
    letter-spacing: 0px;
    color: #00FFFF;
    opacity: 1;
    background: none;
}

.textLink-dark-inactive{
    height: 19px;
    text-align: left;
    text-decoration: underline line-through;
    font: normal normal normal 16px/19px Graphik;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

//textLink-dark-second
.textLink-dark-second{
    height: 19px;
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 16px/19px Graphik;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
.textLink-dark-second:hover{
    height: 19px;
    opacity: 1;
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 16px/19px Graphik;
    letter-spacing: 0px;
    color:#FFFFFF;
    // background: #696969;
}
.textLink-dark-second:focus{
    height: 19px;
    border:1px solid #0041F0;
    text-align: left;
    text-decoration: none;
    font: normal normal normal 16px/19px Graphik;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    // background: #696969;
}
.textLink-dark-second:active {
    height: 19px;
    border:none;
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 16px/19px Graphik;
    letter-spacing: 0px;
    color: #E5E5E5;
    opacity: 1;
    background: none;
}

//btn_primary
.btn_primary{
    margin-top: 15px;
    width: 48px;
    height: 48px;
    background: #A100FF 0% 0% no-repeat padding-box;
    border: none;
    text-align: center;
    border-radius: 999px;
    text-transform: capitalize;
    cursor: pointer;
    outline: none;
    opacity: 1 ;

    .btn_primary_span::before{
        color:#FFFFFF;
        left:-35px;
        position: absolute;
        content: '>';
        opacity: 1;
    }
    .btn_primary_span{
        display: inline-block;
        position: relative;
        left: 48px;
        color: #1D1823;
        font: normal normal bold 18px/18px Graphik;
    }
    .btn_primary_span::after{
        color:#FFFFFF;
        transition: 0.5s;
        left:-160px;
        position: absolute;
        content: '—>';
        opacity: 0;
    }
    .btn_primary_span:focus{
        color:#FFFFFF;
        transition: 0.5s;
        left:-160px;
        position: absolute;
    }
}

.btn_primary:hover{
    width: 180px;
    height: 48px;
    background-color: #7500C0;
    border:none;
    opacity: 1;
    transition: 0.5s;
    .btn_primary_span {
        padding-right: 50px;
        color: #FFFFFF;
      }
    .btn_primary_span::after {
        opacity: 1;
        right: 0;
      }
    .btn_primary_span::before {
        opacity: 0;
      }
}

.btn_primary:focus{
    border: 1px solid #0041F0;
    width: 180px;
    height: 48px;
    background-color: #7500C0;
    .btn_primary_span {
        padding-right: 50px;
        color: #FFFFFF;
      }
}

//btn_secondary
.btn_secondary{
    width: 120px;
    height: 36px;
    background:#A100FF 0% 0% no-repeat padding-box;
    border: none;
    text-align: center;
    border-radius: 999px;
    text-transform: capitalize;
    cursor: pointer;
    font: normal normal 600 18px/18px Graphik;
    color:#FFFFFF;
    outline: none;
    opacity: 1;
}
.btn_secondary:hover{
    background-color: #7500C0;
    color:#FFFFFF;
    border:none;
    opacity: 1;
}

.btn_secondary:focus{
    border: 1px solid #0041F0;
}

.btn_secondary_inactive{
    width: 120px;
    height: 36px;
    border:none;
    background: #A100FF 0% 0% no-repeat padding-box;
    border-radius: 999px;
    opacity: 0.24;
    text-align: center;
    font: normal normal 600 18px/18px Graphik;
    letter-spacing: 0px;
}
.btn_secondary_inactive_span{
    color: #FFFFFF;
    opacity:1;
}
//btn_tertiary
.btn_tertiary{
    margin-top: 15px;
    top: 862px;
    left: 507px;
    width: 120px;
    height: 36px;
    background: #FFFFFF;
    border: 4px solid #A100FF;
    text-align: center;
    border-radius: 999px;
    text-transform: capitalize;
    cursor: pointer;
    font: normal normal 600 18px/18px Graphik;
    color:#A100FF;
    outline: none;
    opacity: 1;
}
.btn_tertiary:hover{
    background-color: #7500C0;
    color:#FFFFFF;
    border:none;
    opacity: 1;
}

.btn_tertiary:focus{
    border: 1px solid #0041F0;
}

//label's style
.session-label {
    @extend .graphik-normal-16;
    display: block;
    margin-bottom: 10px;
    height: 20px;
    font-weight: 600;
    .pi.pi-question-circle{
        display: inline-block;
        vertical-align: bottom;
        padding: 3px 0;
        font-size: 18px;
    }
    &-required{
        @extend .session-label;
        &::after{
            margin-left: 5px;
            content:'*';
            color: $secondary-color-five;
        }
    }
}
.session-tooltip{
    .session-label-required{
        display: inline-block;
    }
    .pi.pi-question-circle{
        display: inline-block;
        margin: 0 0 6px 5px;
        padding: 3px 0;
        vertical-align: bottom;
        font-size: 18px;
    }
}

@font-face {
    font-family: 'Graphik-Regular';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/Graphik-Regular.woff2') format('woff2'),
    url('./assets/fonts/Graphik-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Graphik-Medium';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/Graphik-Medium.woff2') format('woff2'),
    url('./assets/fonts/Graphik-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Graphik-Bold';
    font-style: normal;
    font-weight: 900;
    src: url('./assets/fonts/Graphik-Bold.woff2') format('woff2'),
    url('./assets/fonts/Graphik-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Graphik-Semibold';
    font-style: normal;
    font-weight: 900;
    src: url('./assets/fonts/Graphik-Semibold.woff2') format('woff2'),
    url('./assets/fonts/Graphik-Semibold.woff') format('woff');
}


// flexible button
.btn_flexible{
    position: relative;
    height: 40px;
    width: 165px;
    cursor: pointer;
    label{
        position: absolute;
        z-index: 100;
        top: 0;
        left: 30px;
        height: 40px;
        color: #000000;
        font: normal normal normal 14px/38px Graphik-Bold;
        cursor: pointer;
    }
    .btn_expand{
        position: absolute;
        z-index: 10;
        top: 8px;
        left: 0;
        height: 24px;
        width: 24px;
        border: none;
        border-radius: 50%;
        background-color: #a100ff;
        min-width: auto;
        text-align: left;
        &.ui-button-icon-only{
            width: 24px;
        }
        .pi.pi-chevron-right{
            font-size: 14px;
        }
        &:enabled:hover{
            border-radius: 999px;
            background-color: #7500c0;
            transition: 0.5s;
            .pi.pi-chevron-right{
                left: 25px;
                &:before{
                    content:'\e91b';
                }
            }
        }
        &:enabled:focus{
            border:1px solid #0041F0;
            box-shadow: none;
            transition: 0.5s;
        }
        > span {
            height: 34px;
            line-height: 34px;
        }
    }
    &:hover, &:focus{
        .btn_expand{
            width: 165px;
            border-radius: 999px;
            background-color: #7500c0;
            transition: 0.5s;
            .pi.pi-chevron-right{
                left: 25px;
                &:before{
                    content:'\e91b';
                }
            }
        }
        label{
            left: 40px;
            color: #ffffff;
        }
    }
}

// confirm ui
// only font button
.clear-btn-label {
    margin-left: 15px;
    margin-right: 30px;
    background: #FFFFFF;
    text-transform: capitalize;
    cursor: pointer;
    font: normal normal 600 18px/18px Graphik;
    color:#A100FF;
}

// zorro tree
::ng-deep .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    background-color: $primary-color;
}
::ng-deep .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
    background-color: $primary-color;
}
// history pop
.pop_content_body {
    &.ant-popover-placement-left {
        .ant-popover-arrow {
            top: 32px !important;
        }
    }
    .ant-popover-inner-content{
        padding: 0;
    }
}

.ant-btn-primary
{
    border-color:unset;
    &:hover{
        border-color:unset;
    }
}

.ant-btn {
    height: 36px;
    border-radius: 18px;
    &[disabled]{
        cursor: unset;
        color: #FFFFFF;
        background: #B3B2B5;
        border: none;
        &:hover, &:focus{
            color: #FFFFFF;
            background: #B3B2B5;
            border: none;
        }
    }
    &:focus{
        color: unset;
    }
}

.btn_basic_circle{
    font-family: 'Graphik';
    font-weight: 600;
    border:2px solid #A100FF;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    color: #A100FF;
    box-sizing: border-box;
    position: relative;
    &:hover {
        background-color: #7500C0;
        color: #fff;
        border-color: #7500C0;
    }
}
.user_pop {
    .ant-popover-arrow {
        right: 9px !important;
    }
}

.cdk-overlay-container {
    z-index: 1200;
}

.content_container .row{
    width: calc(100% - 10px);
    margin: 0!important;
    &+.row{
        >div{
            padding-top: px2rem(6px) !important;
        }
    }
    div{
        font-size: px2rem(14px);
        line-height: px2rem(15px);
        padding-top: 0 !important;
        padding-bottom: px2rem(26px) !important;
        padding-left: 0!important;
    }
}
#view-wrapper-header{
    z-index: 29;
}


.transfer_box{
    .ant-checkbox {
        top: 0!important;
        & + span {
            font-family: graphik-m;
            font-size: 16px;
            line-height: px2rem(14px, 12px);
            text-transform: capitalize;
            color: #4f4b53;
            padding-left: 12px;
        }
    }

    .ant-checkbox-wrapper{
        display: flex;
        align-items: center;

    }
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
    border-color: #A100FF;
}
.p-multiselect .p-multiselect-panel{
    width: inherit;
}

// assign lead requestor pop-up - input
.ant-input-affix-wrapper-focused {
    border-color: #a100ff !important;
    box-shadow: none !important;
}

.cur-mul-item {
    overflow: hidden;
    text-overflow: ellipsis;
}

//multiple select
.p-multiselect-panel  {
    .p-multiselect-items {
        .p-multiselect-item {
            padding: 0.31rem 0.857rem;
            &:focus {
                box-shadow: none !important;
            }
            &.p-highlight{
                background-color: #FFFFFF !important;
                color: #a100ff !important;

            }
        }
    }

    .p-multiselect-item {
        // fix accessbility
        overflow: auto;
        .p-highlight {
            background-color: #a100ff !important;
            color: #FFFFFF !important;
        }
    }
}

.p-checkbox {
    .p-checkbox-box {
        width: 16px;
        height: 16px;
        .p-checkbox-icon {
            font-size: 10px;
        }
    }
}

.p-multiselect.p-disabled * {
    pointer-events: auto;
}

.p-multiselect {
    border-color: #000000;
    width: 45%;
    height: 36px;
    border-radius: 6px;
    .p-multiselect-label {
        padding: 3px 7px!important;
        &:hover{
            // padding: 2px 6px !important;
        }
    }
    &:hover{
        .p-multiselect-label {
        }
        .p-multiselect-trigger {
        }
    }
    &:not(p-disabled).p-focus {
        box-shadow: none !important;
        border: 2px solid #A100FF !important;
    }
    :not(.p-disabled) :hover {
        border-color: #a100ff;
    }
    &.p-disabled {
        background-color: #e5e5e5;
        color: rgba(0, 0, 0, 0.4);
        cursor: not-allowed !important;
        .p-multiselect-label {
            padding: 3px 7px !important;
        }
    }
    .p-multiselect-trigger {
        color: #000000;
    }

    .p-multiselect-label-container {
        .p-multiselect-label {
            .p-multiselect-token {
                .p-multiselect-token-label {
                    // fix accessbility
                    overflow: auto;
                    max-width: none !important;
                }

                .p-multiselect-token-icon {
                    color: #a100ff;
                } 
            }
        }
    }
}

.p-dropdown .p-dropdown-label{
    padding: 0.19rem 0.3rem;
}
.p-dropdown {
    border-color: #000000;
    width: 40%;
    height: 36px;

    &:hover{
        border: 2px solid #A100FF !important;
    }
    &:not(p-disabled).p-focus {
        box-shadow: none !important;
        border: 2px solid #A100FF !important;
    }
    :not(.p-disabled) :hover {
        border-color: #a100ff;
    }
    &.p-disabled {
        background-color: #e5e5e5;
        color: rgba(0, 0, 0, 0.4);
        cursor: not-allowed !important;
    }
    .p-multiselect-trigger {
        color: #000000;
    }
}
 .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight{
    background: #a100ff;
}
// assign SR pop-up: input box

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #ffffff !important;
}

.my-spinners {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .7);
    &>.fa.fa-spinner {
        font-size: 1.5rem;
        color: #A100FF;
    }
}

.action-spinners {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 99999;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .7);
    &>.fa.fa-spinner {
        font-size: 1.5rem;
        color: #A100FF;
    }
    padding-top: 120px;
}

.collap_box {
    .ant-collapse-content-box{
        padding-top: 0 !important;
        padding-bottom: 0!important;
    }
    .ant-collapse-arrow{
        left: 0 !important;
    }
    .ant-collapse-header{
        padding-left: 20px !important;
    }
    .childPane{
        .ant-collapse-header{
            padding-right: 0 !important;
        }
    }
}

::ng-deep .p-toast-top-right{
    top: 64px;
}

html {
    overflow: hidden;
}

.highlight-icon {
    position: relative;

    &:before {
        display     : inline-block;
        margin-right: 4px;
        color       : #ff4d4f;
        font-size   : 14px;
        font-family : SimSun, sans-serif;
        line-height : 1;
        content     : '';
        position    : absolute;
        top         : -4px;
        right       : -14px;
    }
}

.country-tooltip-pree {
    max-width: 500px;
}

.discuss-tooltip {
    &.ant-tooltip-placement-bottom {
        .ant-tooltip-arrow {
            // top: -3px;
        }
    }
}

.tooltip-pree, .country-tooltip-pree {
    .ant-tooltip-arrow {
        .ant-tooltip-arrow-content {
            display: flex;
            border: 2px solid #B3B2B5;
            background-color: #FFFFFF;
            width: 13px;
            height: 13px;
        }
    }
    &.ant-tooltip-placement-bottom {
        .ant-tooltip-arrow {
            // top: -3.1px !important;
        }
    }
    &.ant-tooltip-placement-top {
        .ant-tooltip-arrow {
            // bottom: -3.1px;
        }
    }
    &.ant-tooltip-placement-right {
        .ant-tooltip-arrow {
            // left: -3.1px;
        }
    }
    &.ant-tooltip-placement-left {
        .ant-tooltip-arrow {
            // right: -3.1px;
        }
    }
}


.pi {
    color: #A100FF;
}
/* rewriten primeng styles */
.p-toast.p-component {
    opacity: 1;
    &.p-toast-top-right {
        top: 48px;
        right: 40px;
    }
    .p-toast-message-icon {
        display: none;
    }
    .p-toast-icon-close {
        top: 12px;
        right: 12px;
        width: 20px;
        height: 20px;
        position: absolute;
        &:hover {
            background: none;
        }
    }
    .p-toast-message-content {
        padding: 24px 32px 24px 24px;
        .p-toast-message-text {
            margin: 0;
            word-break:break-word;
            .p-toast-detail {
                margin: 0;
                font: 18px/24px graphik;
            }
            .p-toast-summary {
                font: 18px/24px graphik;
            }
        }
    }
}

.p-toast-message-info {
    .p-toast-message-content {
        background-color: #3B3944;

        .p-toast-detail {
            color: #ffffff;
            font-size: 14px;
        }
        .p-toast-icon-close-icon{
            color: #ffffff;
        }
    }
}
/*
 * Rewrite ant comp style strat
*/
//  rewriten radio styles
.ant-radio-wrapper {

    font-size: 16px;
    white-space: normal;
    font-family: "graphik";
    line-height: 28px;

    .ant-radio {
        top: 3px;

        .ant-radio-inner {
            width: 20px;
            height: 20px;
            border-width: 2px;
            position: static;
            &::after {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                margin: 0 !important;
                border-radius: 50%;
            }
        }

        &+span {
            color: #000;
        }

        .ant-radio-inner {
            border-color: #4F4B53 !important;

            &:hover {
                border-color: #a100ff !important;
            }

            &:focus {
                border-color: #a100ff !important;
            }

        }

        &.ant-radio-checked {
            .ant-radio-inner {
                border-color: #a100ff !important;

                &::after {
                    background-color: #a100ff;
                }

                &:hover {
                    border-color: #a100ff !important;
                }

            }

            &+span {
                color: #000;
            }
        }

        &.ant-radio-disabled {
            .ant-radio-inner {
                background-color: #FFFFFF;
                border-color: #837F89 !important;

                &::after {
                    background-color: #FFFFFF;
                }

                &:hover {
                    border-color: #837F89 !important;
                }

            }

            &.ant-radio-checked {
                border-color: #837F89 !important;

                &::after {
                    background-color: #837F89;
                }

                &:hover {
                    border-color: #837F89 !important;
                }

                .ant-radio-inner {
                    &::after {
                        background-color: #837F89;
                    }
                }
            }

            &+span {
                color: #4F4B53;
            }
        }

        &+* {
            padding-left: 22px;
        }

        &+span {
            color: #000;
        }
    }
}
//  rewriten input group styles
.ant-input-affix-wrapper {
    padding: 1px 11px;
    border: 1px solid #837F89;
    color: #000000;
    border-radius: 6px;
    height: 40px;
    line-height: 36px;
    font-family: "graphik";
    font-size: 16px;
    box-sizing: border-box;
    box-shadow: none;
    transition: none;
    min-height: unset;
    .ant-input {
        height: 36px;
        line-height: 36px;
        border-radius: 0;
        padding: 0;
        &:hover {
            padding: 0;
            border: none !important;
            line-height: 36px;
        }
        &:focus {
            border: none !important;
            padding: 0;
            line-height: 36px;
        }
    }
    &:hover {
        border: 2px solid #a100ff !important;
        padding: 0 10px;
        .ant-input {
            height: 36px;
            &:hover {
                border: none !important;
                padding: 0;
            }
            &:focus {
                border: none !important;
                padding: 0;
            }
        }
    }
    &:focus {
        border: 2px solid #a100ff!important;
        padding: 0 10px;
        .ant-input {
            height: 36px;
            &:hover {
                border: none !important;
                padding: 0;
            }
            &:focus {
                border: none !important;
                padding: 0;
            }
        }
    }
    &.ant-input-affix-wrapper-disabled {
        &:hover {
            border: 1px solid #4F4B53!important;
            padding: 1px 11px;
        }
        &:focus {
            border: 1px solid #4F4B53!important;
            padding: 1px 11px;
        }
        .ant-input {
            height: 36px;
            &:hover {
                border: none;
                padding: 0;
                height: 36px;
            }
            &:focus {
                border: none;
                padding: 0;
                height: 36px;
            }
        }
        &:hover {
            .ant-input {
                height: 36px;
                &:hover {
                    border: none!important;
                    padding: 0;
                }
                &:focus {
                    border: none!important;
                    padding: 0;
                }
            }
        }
    }
}
//  rewriten input styles
textarea.ant-input, input.ant-input {
    padding: 7px 10px;
    border: 1px solid #837F89;
    color: #000000;
    border-radius: 6px;
    height: 40px;
    line-height: 20px;
    font-family: "graphik";
    font-size: 16px;
    box-sizing: border-box;
    box-shadow: none;
    outline: 0;
    transition: none;
    min-height: unset;
    &:hover {
        border: 2px solid #a100ff !important;
        padding: 7px 10px;
        line-height: 20px;
    }
    &:focus {
        border: 2px solid #a100ff !important;
        padding: 7px 10px;
        box-shadow: none;
        line-height: 20px;
    }
    &[disabled] {
        background-color: #E5E5E5;
        line-height: 20px;
        color: #000000;
        border-color: #837F89 !important;
        &:hover, &:focus {
            border: 1px solid #4f4b53 !important;
            padding: 7px 10px;
            line-height: 20px;
        }
    }
}
//  rewriten select dropdown styles
.ant-select:not(.ant-select-customize-input) {
    line-height: 40px;
    border-radius: 6px;
    height: 40px;
    .ant-select-selector {
        border: 1px solid #000;
        height: 40px;
        font-family: "graphik";
        font-size: 14px;
        display: flex;
        align-items: center;
        border-radius: 6px;
        padding: 0 11px;
        &:hover {
            border: 2px solid #a100ff !important;
            padding: 0 10px;
        }
        &:focus {
            border: 2px solid #a100ff !important;
            padding: 0 10px;
            box-shadow: none;
        }
    }
    &.ant-select-focused:not(.ant-select-disabled) {
        .ant-select-selector {
            box-shadow: none !important;
            border-color: #a100ff;
        }
    }
    &.ant-select-disabled {
        cursor: not-allowed !important;
        .ant-select-selector {
            background-color: #E5E5E5;
            padding: 0 11px;
            color: #000000 !important;
            pointer-events: none;
        }
    }
    &.ant-select-disabled {
        .ant-select-selector {
            &:hover {
                padding: 0 11px;
                border: 1px solid #000000 !important;
                pointer-events: none;
            }
            &:focus {
                padding: 0 11px;
                border: 1px solid #000000 !important;
                pointer-events: none;
            }
        }
    }
}

.ant-select-dropdown-hidden {
    padding: 0;
}

.ant-select-dropdown {
    .ant-select-item-option {
        background-color: #ffffff;
        .ant-select-item-option-content {
            font-family: Graphik;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #000;
            background-color: transparent;
        }
        &:hover {
            background-color: #f5f5f5;
            .ant-select-item-option-content {
                color: #a100ff;
                font-weight: 600;
                background-color: transparent;
            }
        }
        &.ant-select-item-option-selected {
            background-color: #f5f5f5;
            .ant-select-item-option-content {
                color: #a100ff;
                font-weight: 600;
                background-color: transparent;
            }
        }
        &.ant-select-item-option-disabled {
            background-color: #837F89;
            .ant-select-item-option-content {
                color: #4F4B53;
                background-color: transparent;
            }
            &.ant-select-item-option-selected {
                background-color: #4F4B53;
                .ant-select-item-option-content {
                    color: #000000;
                    background-color: transparent;
                }
            }
        }
    }
}
// rewriten picker styles
// .ant-picker {
//     line-height: 40px;
//     border-radius: 6px;
//     height: 40px;
//     border: 1px solid #000;
//     &:focus, &:hover, .ant-picker-focused {
//         border: 2px solid #a100ff !important;
//     }
//     &.ant-picker-disabled {
//         color: #000000;
//         border: 1px solid #000000;
//         pointer-events: none;
//         background-color: #E5E5E5;
//     }
//     .ant-picker-input {
//         > input[disabled] {
//             color: #000;
//             font-family: 'graphik';
//             font-size: 14px;
//         }
//     }
// }
//  rewriten checkbox styles
.ant-checkbox-wrapper {
    font-size: 16px;
    white-space: normal;
    font-family: "graphik";
    .ant-checkbox {
        // height: 16px;
        & + span {
            color: #000;
        }
        .ant-checkbox-input {
            &:hover + .ant-checkbox-inner{
                border-color: #a100ff;
            }
        }
        .ant-checkbox-inner {
            background-color: #ffffff;
            display: inline-block;
            width: 18px;
            height: 18px;
            border: 2px solid #4f4b53;
            &:hover {
                border-color: #a100ff !important;
            }
            &:focus {
                border-color: #a100ff !important;
            }
        }

        &.ant-checkbox-checked {
            .ant-checkbox-inner {
                background-color: #a100ff ;
                border-color: #a100ff !important;
                &::after {
                    border: 2px solid #ffffff;
                    border-top: 0;
                    border-left: 0;
                    width: 6px;
                    height: 12px;
                    top: 45%;
                    left: 16%;
                }
            }
            &::after {
                border: none;
            }
        }
        &.ant-checkbox-disabled{
            .ant-checkbox-inner {
                background-color: #837F89;
                border-color: #4F4B53 !important;
                &:hover {
                    border-color: #4F4B53 !important;
                }
            }
            &.ant-checkbox-checked {
                .ant-checkbox-inner {
                    background-color: #837F89 ;
                    border-color: #837F89 !important;
                    &::after {
                        border-color:#ffffff!important;
                        border-top: 0;
                        border-left: 0;
                        width: 6px;
                        height: 12px;
                        top: 45%;
                        left: 16%;
                    }
                    &:hover {
                        border-color: #837F89 !important;
                    }
                }

                &::after {
                    border: none;
                }
            }
        }
    }

}

//  rewriten button styles
.ant-btn {
    background-color: #ffffff;
    min-width: 120px;
    padding: 0 7px;
    height: 36px;
    line-height: 18px;
    border: 2px solid #A100FF;
    background-color: #FFFFFF;
    >span {
        font-family: Graphik;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        vertical-align: baseline;
        text-align: center;
        color: #A100FF;
    }
    &.ant-btn-round {
        border-radius: 18px;
        padding: 0 7px;
    }
    &:hover {
        background-color: #7500C0;
        border: 2px solid #7500C0;
        >span {
            color: #FFFFFF;
        }
    }
    &:focus {
        background-color: #7500C0;
        border: 2px solid #7500C0;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            width: calc(100% + 10px);
            height: calc(100% + 10px);
            border-radius: 24px;
            border: 1px solid #0041F0;
            display: block;
            left: -5px;
            top: -5px;
            background: transparent;
        }
        >span {
            color: #FFFFFF;
        }
    }
    &[disabled] {
        border: 2px solid #B3B2B5;
        background-color: #FFFFFF;
        &:hover, &:focus {
            border: 2px solid #B3B2B5;
            background-color: #FFFFFF;
            >span {
                color: #B3B2B5;
            }
        }
        &:focus {
            &::before {
               display: none;
            }
        }
        >span {
            color: #B3B2B5;
        }
    }
    &.ant-btn-primary {
        border: 2px solid #A100FF;
        background-color: #A100FF;
        >span {
            color: #FFFFFF;
        }
        &:hover {
            background-color: #7500C0;
            border: 2px solid #7500C0;
            >span {
                color: #FFFFFF;
            }
        }
        &:focus {
            background-color: #7500C0;
            border: 2px solid #7500C0;
            position: relative;
            >span {
                color: #FFFFFF;
            }
        }
        &[disabled] {
            border: 2px solid #B3B2B5;
            background-color: #B3B2B5;
            &:hover, &:focus {
                border: 2px solid #B3B2B5;
                background-color: #B3B2B5;
                >span {
                    color: #FFFFFF;
                }
            }
            &:focus {
                &::before {
                   display: none;
                }
            }
            >span {
                color: #FFFFFF;
            }
        }
    }
    &.ant-btn-text {
        background-color: transparent;
        border: 2px solid transparent;
        &:hover, &:focus {
            background-color: #FFFFFF;
            border: 2px solid #A100FF;
        }
        &[disabled] {
            &:hover, &:focus {
                background-color: transparent;
                border: 2px solid transparent;
                >span {
                    color: #B3B2B5;
                }
            }
            &:focus {
                &::before {
                   display: none;
                }
            }
            >span {
                color: #B3B2B5;
            }
        }
        >span {
            color: #A100FF;
        }
    }
    &.ant-btn-link {
        background-color: transparent;
        min-width: auto;
        border: 2px solid transparent;
        transform: none;
        >span {
            color: #7500C0;
        }
        &:hover {
            background-color: transparent;
            border: 2px solid transparent;
            >span {
                color: #7500C0;
            }
        }
        &:focus {
            background-color: transparent;
            border: 2px solid transparent;
            &::before {
                display: none;
            }
            >span {
                color: #7500C0;
            }
        }
        &[disabled] {
            border: 2px solid transparent;
            background-color: transparent;
            &:hover, &:focus {
                border: 2px solid transparent;
                background-color: transparent;
                >span {
                    color: #B3B2B5;
                }
            }
            &:focus {
                &::before {
                display: none;
                }
            }
            >span {
                color: #B3B2B5;
            }
        }
    }
}
//  rewriten picker styles
.ant-picker {
    transition: none;
    &:hover, &:focus {
        padding: 3px 10px;
    }
}
/*
 * Rewrite ant comp style end
*/


.assessment-draft-title {
    color: #A100FF;
    font-family: graphik-m;
    text-transform: none;
}

// fix accessbility- when single selection is open, placeholder font color
::ng-deep .ant-select-single.ant-select-open .ant-select-selection-item {
    color: #6c6a70;
}
::ng-deep .ant-tree-select {
    .ant-select-selector {
        .ant-select-selection-search-mirror {
            overflow: auto;
        }
    }
}

.glr-note {
    font-family: graphik-m;
    color: #E32739;
}


.glr-loading  {
    display: block;
    position: relative;
    width: 50px;
    height: 50px;
    margin: 0 auto;
  }
  .glr-loading div {
    position: absolute;
    top: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #A100FF;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .glr-loading div:nth-child(1) {
    left: 8px;
    animation: glr-loading-1 0.6s infinite;
  }
  .glr-loading div:nth-child(2) {
    left: 8px;
    animation: glr-loading-2 0.6s infinite;
  }
  .glr-loading div:nth-child(3) {
    left: 32px;
    animation: glr-loading-2 0.6s infinite;
  }
  .glr-loading div:nth-child(4) {
    left: 56px;
    animation: glr-loading-3 0.6s infinite;
  }
  @keyframes glr-loading-1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes glr-loading-3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes glr-loading-2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  
  .display-block {
    display: block;
  }

@import 'src/assets/antd.scss';
@import 'src/assets/editor.scss';
