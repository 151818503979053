@import 'src/assets/base.scss';

/* The entire antd UI style rewritten for GLR tool */
.ant-tooltip-arrow-content {
    --antd-arrow-background-color: none;
}

/* antd modal */
.ant-modal {
    top: 25%;

    .ant-modal-content {
        border-radius: 0;
        background: #FFFFFF;
        box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.12);

        .ant-modal-body {
            padding: 32px;
            max-height: 480px;
            border-radius: 0;
            box-shadow: none;
            display: flex;
            flex-direction: column;

            .modal_container {
                position: relative;

                .popup-icon-close {
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                    background: url(../assets/images/popup_ic_clear.svg) no-repeat;
                    top: -24px;
                    right: -24px;
                    position: absolute;
                }

                .modal_title {
                    color: #000;
                    font-family: graphik-b;
                    font-size: $font-24;
                    line-height: 36px;
                    margin-bottom: 24px;
                }

                .modal_body {
                    color: #000;
                    font-size: 16px;
                    line-height: 24px;
                    font-family: graphik;
                }

                .modal_footer {
                    margin-top: 24px;

                    &>button {
                        font-size: 18px;
                        line-height: 24px;
                        height: 36px;
                        padding: 4px 32px;
                        font-family: graphik-s;
                        text-align: center;
                        border-radius: 999px;
                        cursor: pointer;

                        &:focus {
                            &::before {
                                display: none !important;
                            }
                        }
                    }

                    &>button[disabled] {
                        border: 2px solid #B3B2B5;
                        background-color: #FFFFFF;

                        &:hover,
                        &:focus {
                            border: 2px solid #B3B2B5;
                            background-color: #FFFFFF;
                        }
                    }

                    &>button.ant-btn[disabled] {
                        cursor: unset;
                        color: #FFFFFF;
                        background: #B3B2B5;
                        border: none;

                        &:hover,
                        &:focus {
                            color: #FFFFFF;
                            background: #B3B2B5;
                            border: none;
                        }
                    }

                    &>.ok-btn {
                        color: #ffffff;
                        background-color: #A100FF;
                        min-width: auto !important;
                        border: 2px solid #A100FF;

                        &:hover,
                        &:focus {
                            border-color: #7500C0;
                            background-color: #7500C0;
                        }
                    }

                    &>.no-btn {
                        color: #A100FF;
                        background-color: #ffffff;
                        border: 2px solid #ffffff;

                        &:hover,
                        &:focus {
                            border-color: #7500C0;
                            color: #7500C0;
                        }
                    }
                }

            }

        }

        .ant-modal-footer {
            border-top: 0px;
        }

        .required {
            color: #E32739;
        }

    }

    // use for nz-modal
    .popup-icon-close {
        width: 24px;
        height: 24px;
        cursor: pointer;
        top: 8px;
        right: 7px;
        position: absolute;
        background: url(../assets/images/popup_ic_clear.svg) no-repeat;
    }

    .upload-dec-bold {
        margin-bottom: 8px;
        font-family: graphik-s;
        font-size: 16px;
        line-height: 1;
    }

    .upload-dec {
        font-family: graphik;
        margin-bottom: 8px;
        font-size: 12px;
        line-height: 18px;
    }


}


/* antd modal end */


/* antd badge */
.ant-badge {
    .ant-badge-count {
        background: #e32739;
        border: 0px solid #e32739;
        height: 14px;
        width: 14px;
        border-radius: 8px;
        padding: 1px 2px !important;
        display: flex !important;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        box-shadow: none;
        font-family: 'graphik';
        top: 3px;
        right: 1px;

        .ant-scroll-number-only>p.ant-scroll-number-only-unit {
            font-size: 12px;
        }

        .ant-scroll-number {
            font-size: 12px;
        }
    }

}

// .more-than {
//     .ant-badge-count {
//         line-height: 15px;
//         height: 17px;
//     }
//     .ant-scroll-number {
//         overflow: auto;
//     }
// }

.red-border-white-background {
    .ant-badge-count {
        border: 1px solid #e32739;
        background: #ffffff;
        color: #e32739;
        right: 1px;
        top: 3px;
    }
}

.white-border-red-background {
    .ant-badge-count {
        border: 1px solid #ffffff;
        background: #e32739;
        color: #ffffff;
    }
}

.ant-badge-multiple-words {
    &>.ant-scroll-number-only {
        letter-spacing: -3px;

        &:not(:first-child) {
            letter-spacing: 0px;
        }
    }
}

/* antd badge end */

// antd calendar start
.completion-date {
    border: 1px solid #837F89;
    border-radius: 6px;
    height: 40px;
    padding: 12px;

    .calculate-icon {
        background: url(../assets/images/calculate.svg) no-repeat;
        width: 22px;
        height: 22px;
    }

    &:hover {
        border: 2px solid #a100ff !important;
        padding: 4px 10px;

        .ant-picker-suffix {
            color: #a100ff;
            font-weight: 600;

        }
    }

    &:focus {
        padding: 4px 10px;
        border: 2px solid #a100ff;
        box-shadow: none;
    }

    &.ant-picker-disabled {
        border: 1px solid #837F89;
        color: #E5E5E5;

        &:hover,
        &:focus {
            padding: 4px 11px;
            border: 1px solid #4f4b53;
        }

        .ant-input-disabled {
            color: #000000;
        }

        .ant-picker-suffix {
            color: #4F4B53;


    .calculate-icon {
            background: url(../assets/images/calculate_disable.svg) no-repeat;
            width: 22px;
            height: 22px;
        }
    }
    }
    .ant-picker-focused {
        box-shadow: none;
    }

    .ant-picker-today-btn {
        color: #a100ff;
    }

    .ant-picker-input {
        input {
            font-size: 16px;
            font-family: graphik;
        }

    }
}

.review-part {
    width: 385px;
}

// antd calendar end
